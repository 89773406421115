import { GetStaticProps, NextPage } from 'next';
import dynamic from 'next/dynamic';

import {
  datoApi,
  defaultSettings,
  getApiSettingsFromHost,
} from '@hultafors/eripiowear/api';
import {
  BasePageProps,
  NotFoundPageFragment,
} from '@hultafors/eripiowear/types';

const PageContent = dynamic(() =>
  import('@hultafors/eripiowear/components').then(
    (module) => module.PageContent,
  ),
);

interface NotFoundPageProps extends BasePageProps {
  notFoundPage: NotFoundPageFragment;
}

export const getStaticProps: GetStaticProps<NotFoundPageProps> = async () => {
  try {
    // Fetch default locale data
    const settings = getApiSettingsFromHost(defaultSettings.hostname);
    const dato = datoApi(settings);

    // Fetch page content
    const {
      data: { notFoundPage, globalContent, footer, allMenuItems, site },
    } = await dato.getNotFoundPage();

    const props: NotFoundPageProps = {
      allMenuItems,
      footer,
      globalContent,
      notFoundPage,
      settings,
      site,
    };
    return { props };
  } catch (error) {
    return {
      props: {} as NotFoundPageProps,
    };
  }
};

const Custom404: NextPage<NotFoundPageProps> = ({ notFoundPage, site }) => {
  const title = `${notFoundPage.title || site.globalSeo.fallbackSeo.title}${
    site.globalSeo.titleSuffix
  }`;
  const description =
    notFoundPage.description || site.globalSeo.fallbackSeo.description;
  const image = site.globalSeo.fallbackSeo.image.url;
  return (
    <PageContent
      content={notFoundPage.content}
      title={title}
      description={description}
      image={image}
    />
  );
};

export default Custom404;
